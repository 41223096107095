<template>
  <section>
    <h5 class="my-5">
      Listado de todas las Solicitudes
    </h5>
    <v-row align-content="start" align-md="start" class="mt-1">
      <v-col cols="12" md="4" sm="12">
        <v-text-field
          v-model="filters.filter"
          @input="getRequestsData(1)"
          clearable
          placeholder="Buscar:"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-autocomplete
          v-model="filters.project_code"
          clearable
          :items="
            projects.items
              ? projects.items.filter((item) => item.warehouse)
              : []
          "
          @input="getRequestsData(1)"
          @update:search-input="getProjectsData"
          @click:clear="getProjectsData"
          class=""
          hide-details
          item-text="name_code"
          no-data-text="Sin proyectos"
          :loading="loadingProjects"
          label="Filtrar por Proyecto"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="mt-2">
        <v-btn class="float-right mt-2" color="blue darken-2 text-white" small>
          <v-icon small class="mr-1">
            mdi-export
          </v-icon>
          Exportar
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-3 px-3 mb-2 mt-2"
      :headers="tableHeaders"
      :items="wrappedRequests.items"
      :items-per-page="20"
      :page.sync="filters.page"
      :loading="loading"
      loading-text="Cargando solicitudes"
      item-key="id"
      hide-default-footer
      no-data-text="Sin solicitudes"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.created_at="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ new Date(item.created_at).toLocaleDateString() }}
        </span>
      </template>
      <template v-slot:item.stage="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.stage_code ? item.stage_code : "Sin etapa" }}
        </span>
      </template>
      <template v-slot:item.request_type="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.request_type.name }}
        </span>
      </template>
      <template v-slot:item.warehouse_code="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.project ? item.project.warehouse_code : "-" }}
        </span>
      </template>
      <template v-slot:item.code="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.project ? item.project.code : item.cost_center.sap_code }}
        </span>
      </template>
      <template v-slot:item.sap_code="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.sap_code }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openModalMaterialsRequest(item)"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                x-small
                class="mx-2"
              >
                <v-icon>
                  mdi-view-list-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Ver materiales de la solicitud</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <div class="float-right">
      <v-pagination
        total-visible="8"
        v-model="filters.page"
        :length="wrappedRequests ? wrappedRequests.number_of_pages : 1"
      ></v-pagination>
    </div>
    <modal-materials-request
      :modalAction="dialogMaterialsRequest"
      :closeModalMaterialsRequest="closeModalMaterialsRequest"
      :currentRequest="currentRequest"
    ></modal-materials-request>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalMaterialsRequest from "./ModalMaterialsRequest.vue";
export default {
  components: {
    ModalMaterialsRequest,
  },
  mounted() {
    this.getRequestsData();
    this.getProjectsData();
  },
  data() {
    return {
      dialogMaterialsRequest: false,
      currentRequest: null,
      loading: false,
      debounceRequests: null,
      loadingProjects: false,
      debounceProjects: null,
      debounceTime: 500,
      filters: {
        page: 1,
        filter: "",
        project_code: null,
      },
      tableHeaders: [
        {
          text: "Acción",
          align: "center",
          value: "actions",
          className: "px-0",
          width: "1%",
          sortable: false,
        },
        {
          text: "Fecha de creación",
          align: "center",
          value: "created_at",
          width: "3%",
          sortable: false,
        },
        {
          text: "Proyecto/CECO",
          align: "center",
          value: "code",
          width: "3%",
          sortable: false,
        },
        {
          text: "Etapa proyecto",
          align: "center",
          value: "stage",
          width: "2%",
          sortable: false,
        },
        {
          text: "Código SAP",
          align: "center",
          value: "sap_code",
          width: "3%",
          sortable: false,
        },
        {
          text: "Tipo de solicitud",
          align: "center",
          value: "request_type",
          width: "6%",
          sortable: false,
        },
        {
          text: "Código bodega",
          sortable: false,
          width: "3%",
          align: "center",
          value: "warehouse_code",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getProjects: "requestMaterialsSap/getProjects",
      getAllRequests: "requestMaterialsSap/getAllRequests",
    }),
    async getRequestsData(page = 1) {
      let self = this;
      clearTimeout(self.debounceRequests);
      self.debounceRequests = setTimeout(async () => {
        self.loading = true;
        await self.getAllRequests({
          page,
          filter: self.filters.filter ? self.filters.filter : undefined,
          project_filter: self.filters.project_code
            ? self.filters.project_code.split(" - ")[0]
            : undefined,
        });
        self.loading = false;
      }, self.debounceTime);
    },
    async getProjectsData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") &&
          this.filters.project_code !== search)
      ) {
        this.loadingProjects = true;
        clearTimeout(this.debounceProjects);
        let self = this;
        this.debounceProjects = setTimeout(async function() {
          await self.getProjects({
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingProjects = false;
        }, self.debounceTime);
      }
    },
    openModalMaterialsRequest(request) {
      this.currentRequest = request;
      this.dialogMaterialsRequest = true;
    },
    closeModalMaterialsRequest() {
      this.currentRequest = null;
      this.dialogMaterialsRequest = false;
    },
  },
  computed: {
    ...mapGetters({
      projects: "requestMaterialsSap/projects",
      allRequests: "requestMaterialsSap/allRequests",
    }),
    wrappedRequests() {
      return this.allRequests;
    },
  },
  watch: {
    "filters.page": function(page) {
      this.getRequestsData(page);
    },
  },
};
</script>

<style></style>
