<template>
  <section class="mt-5 pt-5">
    <h5 class="my-5">
      Gráfico de Solicitudes creadas mensualmente
    </h5>

    <hr class="mb-2" />

    <apexchart
      v-if="chartData.chartoptions.xaxis.categories.length"
      class="apex-charts"
      dir="ltr"
      height="400"
      :series="chartData.series"
      :options="chartData.chartoptions"
    ></apexchart>
    <p v-else class="text-center mt-5">
      Cargando Gráfico...
    </p>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  mounted() {
    this.getAndSetRequestsChartData();
  },
  data() {
    return {
      mapMonths: {
        January: "Ene",
        February: "Feb",
        March: "Mar",
        April: "Abr",
        May: "May",
        June: "Jun",
        July: "Jul",
        August: "Ago",
        September: "Sep",
        October: "Oct",
        November: "Nov",
        December: "Dic",
      },
      chartData: {
        series: [
          {
            name: "Solicitudes de Llenado Inicial",
            data: [],
          },
          {
            name: "Solicitudes de Material",
            data: [],
          },
          {
            name: "Solicitudes de Material Urgente",
            data: [],
          },
        ],
        chartoptions: {
          chart: {
            height: 350,
            type: "area",
            toolbar: {
              show: false,
            },
          },
          colors: ["#556ee6", "#34c38f", "#f1b44c"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            width: 3,
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [20, 100, 100, 200],
            },
          },
          xaxis: {
            categories: [],
          },
          markers: {
            size: 4,
            strokeWidth: 4,
            hover: {
              size: 4,
              sizeOffset: 2,
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "right",
          },
        },
      },
      selection: "one_year",
    };
  },
  methods: {
    ...mapActions({
      getAllRequestsChartData: "requestMaterialsSap/getAllRequestsChartData",
    }),
    async getAndSetRequestsChartData() {
      const dataChart = await this.getAllRequestsChartData();
      Object.entries(dataChart)
        .reverse()
        .forEach((chartElement) => {
          this.chartData.chartoptions.xaxis.categories.push(
            this.mapMonths[chartElement[0].split("_")[0]]
          );
          this.chartData.series[0].data.push(
            chartElement[1]["SOLICITUD_LLENADO_INICIAL"][0]
          );
          this.chartData.series[1].data.push(
            chartElement[1]["SOLICITUD_MATERIALES"][0]
          );
          this.chartData.series[2].data.push(
            chartElement[1]["SOLICITUD_MATERIAL_URGENTE"][0]
          );
        });
    },
  },
};
</script>
