var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-dialog',{attrs:{"max-width":"1200px","scrollable":""},on:{"keydown":_vm.executeCloseModalMaterialsRequest,"click:outside":_vm.executeCloseModalMaterialsRequest},model:{value:(_vm.modalAction),callback:function ($$v) {_vm.modalAction=$$v},expression:"modalAction"}},[(_vm.currentRequest)?_c('v-card',[_c('div',{staticClass:"p-4 pb-0"},[_c('h5',[_vm._v(" Código SAP Solicitud: "),_c('b',[_vm._v(_vm._s(_vm.currentRequest.sap_code))]),_vm._v(" - Proyecto/CECO: "),_c('b',[_vm._v(" "+_vm._s(_vm.currentRequest.project ? _vm.currentRequest.project.code : _vm.currentReques.cost_center.sap_code)+" ")])])]),_c('hr'),_c('v-card-text',[_c('v-container',{staticClass:"pt-0"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('section',{staticClass:"w-25"},[_c('v-text-field',{staticClass:"px-2",attrs:{"clearable":"","placeholder":"Buscar:"},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1)]),_c('v-data-table',{staticClass:"px-2 elevation-4 pt-2",attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredMaterials,"items-per-page":200,"hide-default-footer":"","loading-text":"Cargando materiales","item-key":"id","no-data-text":"Sin materiales"},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-start"},[_c('v-chip',{staticClass:"mt-0 text-white",attrs:{"label":"","small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.code)+" ")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-left my-0",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center my-0",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.unit)+" ")])]}},{key:"item.sap_code",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-left my-0",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.sap_code)+" ")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-right mt-4",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}}],null,false,1004061674)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":_vm.executeCloseModalMaterialsRequest}},[_c('small',[_vm._v(" Cerrar ")])])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }