var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('h5',{staticClass:"my-5"},[_vm._v(" Listado de todas las Solicitudes ")]),_c('v-row',{staticClass:"mt-1",attrs:{"align-content":"start","align-md":"start"}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"clearable":"","placeholder":"Buscar:"},on:{"input":function($event){return _vm.getRequestsData(1)}},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.projects.items
            ? _vm.projects.items.filter(function (item) { return item.warehouse; })
            : [],"hide-details":"","item-text":"name_code","no-data-text":"Sin proyectos","loading":_vm.loadingProjects,"label":"Filtrar por Proyecto"},on:{"input":function($event){return _vm.getRequestsData(1)},"update:search-input":_vm.getProjectsData,"click:clear":_vm.getProjectsData},model:{value:(_vm.filters.project_code),callback:function ($$v) {_vm.$set(_vm.filters, "project_code", $$v)},expression:"filters.project_code"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-btn',{staticClass:"float-right mt-2",attrs:{"color":"blue darken-2 text-white","small":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-export ")]),_vm._v(" Exportar ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-3 px-3 mb-2 mt-2",attrs:{"headers":_vm.tableHeaders,"items":_vm.wrappedRequests.items,"items-per-page":20,"page":_vm.filters.page,"loading":_vm.loading,"loading-text":"Cargando solicitudes","item-key":"id","hide-default-footer":"","no-data-text":"Sin solicitudes"},on:{"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleDateString())+" ")])]}},{key:"item.stage",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.stage_code ? item.stage_code : "Sin etapa")+" ")])]}},{key:"item.request_type",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.request_type.name)+" ")])]}},{key:"item.warehouse_code",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.project ? item.project.warehouse_code : "-")+" ")])]}},{key:"item.code",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.project ? item.project.code : item.cost_center.sap_code)+" ")])]}},{key:"item.sap_code",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.sap_code)+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":"","color":"primary","x-small":""},on:{"click":function($event){return _vm.openModalMaterialsRequest(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-view-list-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver materiales de la solicitud")])])],1)]}}])}),_c('div',{staticClass:"float-right"},[_c('v-pagination',{attrs:{"total-visible":"8","length":_vm.wrappedRequests ? _vm.wrappedRequests.number_of_pages : 1},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1),_c('modal-materials-request',{attrs:{"modalAction":_vm.dialogMaterialsRequest,"closeModalMaterialsRequest":_vm.closeModalMaterialsRequest,"currentRequest":_vm.currentRequest}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }