<template>
  <v-container class="px-3 py-4">
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="my-2">
        Dashboard
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <list-requests></list-requests>
    <br />
    <chart-requests></chart-requests>
  </v-container>
</template>

<script>
import ChartRequests from "./components/ChartRequests.vue";
import ListRequests from "./components/ListRequests.vue";
export default {
  components: { ListRequests, ChartRequests },
  data() {
    return {
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: "Dashboard",
          disabled: true,
          href: "/dashboard",
        },
      ],
    };
  },
};
</script>

<style></style>
